import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { navigate, useStaticQuery, graphql } from 'gatsby';
import { Layout, Hero, Container, Fade } from '~/components';
import { Button } from '~/elements';
import { mq } from '~/Theme';

const Text = styled.div``;

const Content = styled(Container)`
  max-width: 960px;

  margin-bottom: 80px;
  padding: 0;

  ${mq.lessThan('lg')`
    margin-bottom: 60px;
  `}

  ${mq.lessThan('md')`
    margin-bottom: 40px;
  `}

  ${mq.lessThan('sm')`
    margin-bottom: 32px;
  `}

  p {
    margin: 0;

    &:not(:first-child) {
      margin-top: 8px;
    }

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
`;

const NotFoundPage: React.FC = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <Layout>
      <Helmet>
        <title>404 Not Found - {data.site.siteMetadata.title}</title>
      </Helmet>
      <Content>
        <Hero
          headline={
            <>
              <span>404</span> <br />
              Page not found
            </>
          }
          smallBottom
        />
        <Fade>
          <Text>
            <Button variant="light" onClick={() => navigate('/')}>
              Back to Home
            </Button>
          </Text>
        </Fade>
      </Content>
    </Layout>
  );
};

export default NotFoundPage;
